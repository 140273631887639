<template>
  <v-row>
    <v-col cols="12">
      <general-form :customer="customer" />
    </v-col>
    <v-col cols="12">
      <policies from-client />
    </v-col>

    <v-col cols="12">
      <address-component />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    components: {
      GeneralForm: () => import('./GeneralForm'),
      AddressComponent: () => import('./Address'),
      Policies: () => import('../../policies/Policies'),
    },

    props: {
      customer: {
        type: Object,
        default: () => ({}),
      },
    },
  }
</script>
